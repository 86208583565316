@value cardBase, pageContainer, gridLayout from '../../styles/shared.module.css';

.container {
  composes: container from '../../styles/base.css';
  width: 100%;
  max-width: 1024px;
  padding: var(--space-4);
  margin: 0 auto;
  position: relative;
  z-index: var(--z-0);
}

.header {
  composes: cardInteractive from '../../styles/base.css';
  position: relative;
  z-index: var(--z-0);
  padding: var(--space-6) var(--space-4);
  margin-bottom: var(--space-8);
  text-align: center;
  border-radius: var(--radius-lg);
}

.header h2 {
  margin: 0 0 var(--space-4);
  font-family: var(--font-serif);
  font-size: var(--text-4xl);
  font-weight: 600;
  color: var(--text-primary);
}

.welcome {
  font-size: var(--text-lg);
  line-height: 1.6;
  color: var(--text-secondary);
}

.userName {
  font-weight: 600;
  color: var(--primary-500);
}

.content {
  display: grid;
  gap: var(--space-8);
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 1rem;
  margin: 1.5rem 0;
}

.statCard {
  composes: cardInteractive from '../../styles/base.css';
  position: relative;
  min-width: 140px;
  padding: 1.5rem;
  text-align: center;
  background: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: 0.75rem;
  transition: transform 0.2s ease;
}

.statCard:hover {
  transform: translateY(-3px);
}

.statCard h3 {
  margin-bottom: var(--space-2);
  font-size: var(--text-sm);
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.statValue {
  font-size: var(--text-3xl);
  font-weight: 600;
  line-height: 1;
  color: var(--primary-500);
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--space-4);
  align-items: center;
  margin-top: var(--space-8);
}

.startNewButton {
  composes: btnPrimary from '../../styles/base.css';
  display: inline-flex;
  gap: var(--space-2);
  align-items: center;
  padding: var(--space-3) var(--space-6);
  font-size: var(--text-base);
  font-weight: 500;
}

.startNewButton:hover {
  transform: translateY(-1px);
}

.buttonIcon {
  width: 20px;
  height: 20px;
}

.tip {
  max-width: 400px;
  font-size: var(--text-sm);
  line-height: 1.6;
  color: var(--text-secondary);
  text-align: center;
}

.loading {
  display: flex;
  flex-direction: column;
  gap: var(--space-4);
  align-items: center;
  justify-content: center;
  min-height: 300px;
  color: var(--text-secondary);
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--primary-100);
  border-top-color: var(--primary-500);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.toast {
  position: fixed;
  right: var(--space-4);
  bottom: var(--space-4);
  z-index: var(--z-30);
  padding: var(--space-3) var(--space-4);
  font-size: var(--text-sm);
  color: var(--text-primary);
  cursor: pointer;
  background: var(--card-background);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-lg);
  animation: slideIn 0.3s var(--ease-out);
}

.toastSuccess {
  composes: toast;
  color: var(--success-700);
  background: var(--success-50);
  border: 1px solid var(--success-200);
}

.toastError {
  composes: toast;
  color: var(--error-700);
  background: var(--error-50);
  border: 1px solid var(--error-200);
}

.tournamentBracket {
  composes: cardInteractive from '../../styles/base.css';
  padding: var(--space-8);
  margin: var(--space-8) 0;
  overflow-x: auto;
}

.tournamentBracket h3 {
  margin-bottom: var(--space-8);
  font-size: var(--text-2xl);
  color: var(--text-primary);
  text-align: center;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .header {
    background: var(--surface-dark);
  }

  .userName {
    color: var(--primary-400);
  }

  .statCard {
    background: var(--card-background-dark);
    border-color: var(--border-color-dark);
  }

  .statValue {
    color: var(--primary-400);
  }

  .loadingSpinner {
    border-color: var(--primary-900);
    border-top-color: var(--primary-400);
  }

  .tournamentBracket {
    background: var(--surface-dark);
  }

  .resultsHeader {
    background: linear-gradient(135deg, var(--primary-800), var(--primary-900));
    border-color: var(--primary-700);
  }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .container {
    padding: var(--space-3);
  }

  .header {
    padding: var(--space-4);
    margin-bottom: var(--space-6);
  }

  .header h2 {
    font-size: var(--text-3xl);
  }

  .welcome {
    font-size: var(--text-base);
  }

  .content {
    gap: var(--space-6);
  }

  .statsGrid {
    gap: var(--space-3);
  }

  .statCard {
    flex: 1;
    min-width: 110px;
  }

  .tournamentBracket {
    padding: var(--space-4);
    margin: var(--space-4) 0;
  }

  .tournamentBracket h3 {
    font-size: var(--text-xl);
    margin-bottom: var(--space-4);
  }
}

@media (max-width: 768px) {
  .container {
    padding: var(--space-2);
  }

  .header {
    padding: var(--space-3);
    margin-bottom: var(--space-4);
  }

  .header h2 {
    font-size: var(--text-2xl);
  }

  .welcome {
    font-size: var(--text-sm);
  }

  .content {
    gap: var(--space-4);
  }

  .statsGrid {
    flex-direction: column;
    gap: var(--space-2);
  }

  .statCard {
    width: 100%;
  }

  .actions {
    gap: var(--space-3);
    margin-top: var(--space-4);
  }

  .startNewButton {
    width: 100%;
    justify-content: center;
  }

  .tip {
    font-size: var(--text-xs);
  }

  .toast {
    left: var(--space-2);
    right: var(--space-2);
    bottom: var(--space-2);
    text-align: center;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .loadingSpinner {
    animation: none;
  }

  .toast {
    animation: none;
  }

  .startNewButton:hover {
    transform: none;
  }
}

/* Results Header */
.resultsHeader {
  background: linear-gradient(135deg, var(--primary-100), var(--primary-50));
  border: 1px solid var(--primary-200);
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
}

/* Results Grid */
.resultsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--space-4);
  margin-bottom: var(--space-6);
}

/* Tournament Bracket */
.bracketContainer {
  composes: cardInteractive from '../../styles/base.css';
  padding: var(--space-4);
  margin-bottom: var(--space-6);
  overflow-x: auto;
}

/* Actions Section */
.actionsContainer {
  display: flex;
  gap: var(--space-4);
  justify-content: center;
  margin-bottom: var(--space-6);
}

.actionButton {
  composes: btnPrimary from '../../styles/base.css';
  min-width: 200px;
}

/* Toast Notifications */
.toast {
  position: fixed;
  bottom: var(--space-4);
  left: 50%;
  transform: translateX(-50%);
  padding: var(--space-4);
  border-radius: var(--radius-lg);
  animation: slideUp 0.3s var(--ease-out);
  z-index: 1000;
} 