/* Color Variables */
:root {
  /* Light Mode Colors */
  --bg-primary: #ffffff;
  --bg-secondary: #f3f4f6;
  --text-primary: #111827;
  --text-secondary: #6b7280;
  --border-color: #e5e7eb;
  --accent-primary: #4f46e5;
  --accent-primary-hover: #4338ca;
  --accent-danger: #dc2626;
  --accent-danger-hover: #b91c1c;
  --accent-success: #059669;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 2px 4px rgba(0, 0, 0, 0.1);
  --overlay-light: rgba(255, 255, 255, 0.1);
  --overlay-dark: rgba(0, 0, 0, 0.1);
}

/* Dark Mode Colors */
@media (prefers-color-scheme: dark) {
  :root {
    --bg-primary: #1f2937;
    --bg-secondary: #111827;
    --text-primary: #f9fafb;
    --text-secondary: #9ca3af;
    --border-color: #374151;
    --accent-primary: #6366f1;
    --accent-primary-hover: #818cf8;
    --accent-danger: #ef4444;
    --accent-danger-hover: #f87171;
    --accent-success: #10b981;
    --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.3);
    --shadow-md: 0 2px 4px rgba(0, 0, 0, 0.4);
    --overlay-light: rgba(255, 255, 255, 0.05);
    --overlay-dark: rgba(0, 0, 0, 0.2);
  }
}

/* Container Layouts */
.profileContainer {
  padding: 1.5rem;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg-secondary);
  position: relative;
  z-index: 1;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: var(--bg-primary);
  border-radius: 1rem;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.profileHeader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(
    to right,
    var(--accent-primary),
    var(--accent-success)
  );
}

.profileHeader h1 {
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.profileHeader h1::before {
  content: '👤';
  font-size: 1.5rem;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Button Styles */
.button {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid transparent;
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.button:hover {
  background-color: var(--accent-primary-hover);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button.delete {
  background-color: var(--accent-danger);
}

.button.delete:hover {
  background-color: var(--accent-danger-hover);
}

.button.hidden {
  background-color: var(--text-secondary);
}

.button.primary {
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  border: 1px solid var(--accent-primary);
}

.button.primary:hover {
  background-color: var(--accent-primary-hover);
  border-color: var(--accent-primary-hover);
}

/* Admin Controls */
.controls {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  align-items: center;
}

.controls .button {
  min-width: 80px;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.sortControls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sortSelect {
  height: 28px;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 0.75rem;
}

/* Card Components */
.card {
  background-color: var(--bg-primary);
  border-radius: 0.375rem;
  box-shadow: var(--shadow-sm);
  padding: 0.75rem;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid var(--border-color);
}

.card:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

/* Stats Section */
.statsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;
  text-align: center;
  background-color: var(--bg-primary);
}

.statLabel {
  font-size: 0.75rem;
  color: var(--text-secondary);
  margin-bottom: 0.25rem;
}

.statValue {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
}

.statEmoji {
  font-size: 1.125rem;
  margin-top: 0.25rem;
}

/* Ratings Grid */
.ratingsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.25rem;
  padding: 1rem;
}

.nameCard {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 0.75rem;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  overflow: hidden;
  height: 100%;
  min-height: 120px;
  max-width: 320px;
  margin: 0 auto;
}

.nameCard:not(.isHidden) {
  background: linear-gradient(
    135deg,
    var(--bg-primary) 0%,
    color-mix(in srgb, var(--success-50) 10%, var(--bg-primary)) 100%
  );
  border-left: 4px solid var(--success-400);
}

.nameCard.isHidden {
  background: linear-gradient(
    135deg,
    var(--bg-primary) 0%,
    color-mix(in srgb, var(--warning-50) 10%, var(--bg-primary)) 100%
  );
  border-left: 4px solid var(--warning-400);
  position: relative;
}

.nameCard.isHidden .nameTitle::after {
  content: "Hidden";
  font-size: 0.7rem;
  font-weight: normal;
  color: var(--warning-600);
  background: var(--warning-100);
  padding: 0.2rem 0.4rem;
  border-radius: 1rem;
  margin-left: 0.5rem;
  vertical-align: middle;
}

.nameCard.isHidden .rating {
  opacity: 0.8;
}

.nameCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.nameHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.nameTitle {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  flex: 1;
}

.rating {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--accent-primary);
  padding: 0.25rem 0.75rem;
  background: var(--bg-secondary);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.ratingLabel {
  font-size: 0.75rem;
  color: var(--text-secondary);
  font-weight: 500;
}

.stats {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.record {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: var(--text-secondary);
  flex-wrap: wrap;
}

.wins, .losses, .winRate {
  font-weight: 500;
}

.separator {
  color: var(--text-secondary);
  opacity: 0.5;
}

.additionalStats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem;
  background: var(--bg-secondary);
  border-radius: 0.5rem;
  margin-top: 0.25rem;
}

.statRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}

.statLabel {
  color: var(--text-secondary);
}

.statValue {
  font-weight: 500;
  color: var(--text-primary);
}

.cardActions {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.hiddenStatus {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background: var(--bg-secondary);
  border-radius: 0.375rem;
  border: 1px solid var(--border-color);
}

.hiddenText {
  margin: 0;
  font-size: 0.875rem;
  color: var(--text-secondary);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .nameCard:not(.isHidden) {
    background: linear-gradient(
      135deg,
      var(--bg-primary) 0%,
      color-mix(in srgb, var(--success-900) 15%, var(--bg-primary)) 100%
    );
    border-left: 4px solid var(--success-500);
  }

  .nameCard.isHidden {
    background: linear-gradient(
      135deg,
      var(--bg-primary) 0%,
      color-mix(in srgb, var(--warning-900) 15%, var(--bg-primary)) 100%
    );
    border-left: 4px solid var(--warning-500);
  }

  .nameCard.isHidden .nameTitle::after {
    background: color-mix(in srgb, var(--warning-900) 30%, var(--bg-primary));
    color: var(--warning-300);
  }

  .stats {
    background: var(--surface-dark);
  }

  .wins {
    color: var(--success-400);
    background: rgb(var(--success-rgb) / 0.2);
  }

  .losses {
    color: var(--error-400);
    background: rgb(var(--error-rgb) / 0.2);
  }

  .winRate {
    color: var(--primary-400);
    background: rgb(var(--primary-rgb) / 0.2);
  }

  .matchInfo {
    border-color: var(--border-color-dark);
  }

  .profileContainer {
    background-color: var(--bg-secondary);
  }

  .profileHeader {
    background-color: color-mix(in srgb, var(--bg-primary) 95%, var(--accent-primary));
    border-color: color-mix(in srgb, var(--border-color) 50%, var(--accent-primary));
  }

  .profileHeader::before {
    background: linear-gradient(
      to right,
      var(--accent-primary),
      color-mix(in srgb, var(--accent-success) 70%, var(--bg-primary))
    );
    opacity: 0.8;
  }

  .mainContent {
    background-color: transparent;
  }

  .aggregatedHeader {
    background-color: color-mix(in srgb, var(--bg-primary) 95%, var(--accent-primary));
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid color-mix(in srgb, var(--border-color) 60%, transparent);
  }

  .aggregatedHeader .sectionTitle {
    color: var(--text-primary);
  }
  
  .aggregatedHeader .sectionSubtitle {
    color: color-mix(in srgb, var(--text-secondary) 80%, white);
  }
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
  .nameCard,
  .rating,
  .stats,
  .visibilityToggle,
  .visibilityIcon {
    transition: none;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .statsContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .ratingsGrid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 0.25rem;
  }

  .profileHeader {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    padding: 1rem;
  }

  .profileHeader h1 {
    font-size: 1.5rem;
  }

  .chartsContainer {
    padding: 0.5rem;
  }

  .chartRow {
    flex-direction: column;
  }

  .chartWrapper,
  .pieChartWrapper {
    width: 100%;
    min-height: 300px;
  }
}

@media (max-width: 480px) {
  .statsContainer {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .statItem {
    padding: 0.75rem;
    border-radius: 0.5rem;
  }

  .ratingsGrid {
    grid-template-columns: 1fr;
    gap: 0.75rem;
    padding: 0.25rem;
  }

  .nameCard {
    padding: 1rem;
    min-height: 100px;
    border-radius: 0.75rem;
  }

  .nameHeader {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.75rem;
    align-items: flex-start;
  }

  .nameTitle {
    font-size: 1.125rem;
    flex: 1;
    min-width: 150px;
  }

  .rating {
    padding: 0.375rem 0.75rem;
    border-radius: 0.5rem;
    font-size: 1rem;
  }

  .stats {
    margin: 0.75rem 0;
    padding: 0.75rem;
    border-radius: 0.5rem;
  }

  .record {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-1);
  }

  .separator {
    display: none;
  }

  .matchInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-1);
  }

  .cardActions {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .button {
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }

  .profileContainer {
    padding: 0.75rem;
  }

  .profileHeader {
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 0.75rem;
  }

  .profileHeader h1 {
    font-size: 1.5rem;
  }

  .visibilityToggle {
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }

  .hiddenStatus {
    margin: 0.75rem 0;
    padding: 0.75rem;
    border-radius: 0.5rem;
  }

  .chartWrapper,
  .pieChartWrapper {
    height: 250px;
    padding: 0.75rem;
    border-radius: 0.75rem;
  }
}

/* Touch feedback - keeping it minimal */
@media (hover: none) and (pointer: coarse) {
  .button,
  .nameCard,
  .statItem,
  .visibilityToggle {
    cursor: default;
    -webkit-tap-highlight-color: transparent;
  }

  .button:active,
  .nameCard:active,
  .statItem:active,
  .visibilityToggle:active {
    opacity: 0.8;
  }
}

/* Admin Panel */
.adminPanel {
  background-color: var(--bg-primary);
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  max-height: 800px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.adminPanel.minimized {
  max-height: 85px;
  overflow: hidden;
}

.adminHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 2;
}

.adminControls {
  transition: opacity 0.3s ease;
}

.adminPanel.minimized .adminControls,
.adminPanel.minimized .userList {
  opacity: 0;
  pointer-events: none;
}

.minimizeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: var(--text-secondary);
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.minimizeButton:hover {
  color: var(--text-primary);
}

.minimizeButton.minimized {
  transform: rotate(180deg);
}

.userList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1rem;
  max-height: 600px;
  overflow-y: auto;
  padding-right: 0.5rem;
  transition: opacity 0.3s ease;
}

.userList::-webkit-scrollbar {
  width: 8px;
}

.userList::-webkit-scrollbar-track {
  background: var(--bg-secondary);
  border-radius: 4px;
}

.userList::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 4px;
}

.userList::-webkit-scrollbar-thumb:hover {
  background: var(--text-secondary);
}

.userCard {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 0.75rem;
  transition: all 0.2s ease-out;
  min-height: 160px;
  max-height: 200px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

.userCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(
    to right,
    var(--accent-primary),
    var(--accent-success)
  );
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.userCard:hover {
  transform: translateY(-2px);
  border-color: var(--accent-primary);
  box-shadow: var(--shadow-md);
}

.userCard:hover::before {
  opacity: 1;
}

.userInfo {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex: 1;
}

.userName {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.userName::before {
  content: '👤';
  font-size: 1.125rem;
}

.userStats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  margin: 0.5rem 0;
  background: var(--bg-secondary);
  padding: 0.75rem;
  border-radius: 0.5rem;
}

.userStat {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem;
  background: var(--bg-primary);
  border-radius: 0.375rem;
  transition: transform 0.2s ease-out;
}

.userCard:hover .userStat {
  transform: translateY(-1px);
}

.userStatLabel {
  font-size: 0.75rem;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.userStatLabel::before {
  font-size: 0.875rem;
}

.userStat:first-child .userStatLabel::before {
  content: '📊';
}

.userStat:last-child .userStatLabel::before {
  content: '⭐';
}

.userStatValue {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
}

.lastActive {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 0.375rem;
}

.lastActive::before {
  content: '🕒';
  font-size: 0.875rem;
}

.userCard.active {
  border-color: var(--accent-primary);
  background: linear-gradient(
    165deg,
    color-mix(in srgb, var(--bg-primary) 97%, var(--accent-primary)),
    var(--bg-primary)
  );
}

.userCard.active::before {
  opacity: 1;
}

.userCard.active .userStats {
  background: color-mix(in srgb, var(--bg-secondary) 95%, var(--accent-primary));
}

.userActions {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  padding-top: 0.75rem;
  border-top: 1px solid var(--border-color);
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.userCard:hover .userActions,
.userCard.active .userActions {
  opacity: 1;
}

/* Dark mode refinements */
@media (prefers-color-scheme: dark) {
  .userCard.active {
    background: linear-gradient(
      165deg,
      color-mix(in srgb, var(--bg-primary) 90%, var(--accent-primary)),
      var(--bg-primary)
    );
  }

  .userCard.active .userStats {
    background: color-mix(in srgb, var(--bg-secondary) 85%, var(--accent-primary));
  }

  .userStat {
    background: color-mix(in srgb, var(--bg-primary) 95%, transparent);
  }
}

/* Mobile refinements */
@media (max-width: 640px) {
  .userCard {
    padding: 1rem;
  }

  .userStats {
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .userStat {
    padding: 0.375rem;
  }

  .userName {
    font-size: 1.125rem;
  }

  .userStatValue {
    font-size: 1rem;
  }
}

/* Ratings Sections */
.ratingSections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.sectionTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--border-color);
}

.sectionSubtitle {
  font-size: 0.875rem;
  color: var(--text-secondary);
  font-weight: normal;
  margin-left: 0.5rem;
}

.sectionIcon {
  font-size: 1.5rem;
}

/* Hidden Names Section */
.hiddenNamesSection {
  background-color: var(--bg-secondary);
  padding: 1rem;
  border-radius: 0.5rem;
  border: 2px dashed var(--border-color);
}

.nameCard.isHidden {
  background-color: var(--bg-primary);
  border: 2px dashed var(--border-color);
  opacity: 0.9;
}

.nameCard.isHidden:hover {
  border-color: var(--accent-primary);
  opacity: 1;
}

.hiddenStatus {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: var(--bg-secondary);
  border-radius: 0.25rem;
  text-align: center;
  border: 1px solid var(--border-color);
}

.hiddenText {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.hiddenText::before {
  content: '🔒';
  font-size: 0.875rem;
}

.warningText {
  color: var(--accent-danger);
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.visibilityToggle {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s;
}

.visibilityToggle:hover {
  background-color: var(--bg-primary);
  border-color: var(--accent-primary);
  color: var(--accent-primary);
}

.visibilityToggle.hidden {
  background-color: var(--accent-danger);
  color: var(--bg-primary);
  border-color: var(--accent-danger);
}

.visibilityToggle.hidden:hover {
  background-color: var(--accent-danger-hover);
}

.visibilityIcon {
  font-size: 1rem;
}

.cardActions {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.headerActions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.headerActions .button {
  min-width: 100px;
}

.aggregatedSection {
  background-color: var(--bg-primary);
  border-radius: 1rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
}

.aggregatedCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.25rem;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  transition: all 0.3s ease;
}

.aggregatedCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  border-color: var(--accent-primary);
}

.aggregatedStats {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: var(--bg-secondary);
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}

.statRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}

.statLabel {
  color: var(--text-secondary);
}

.statValue {
  font-weight: 600;
  color: var(--text-primary);
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .aggregatedCard {
    background: color-mix(in srgb, var(--bg-primary) 95%, var(--accent-primary));
  }

  .aggregatedCard:hover {
    background: color-mix(in srgb, var(--bg-primary) 90%, var(--accent-primary));
  }

  .aggregatedStats {
    background-color: color-mix(in srgb, var(--bg-secondary) 80%, transparent);
  }
}

.chartsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
  background-color: var(--bg-primary);
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid var(--border-color);
}

.chartRow {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 1.5rem;
  width: 100%;
}

.chartWrapper {
  height: 400px;
  padding: 1rem;
  background-color: var(--bg-primary);
  border-radius: 0.5rem;
  border: 1px solid var(--border-color);
  transition: all 0.3s ease;
}

.pieChartWrapper {
  height: 400px;
  padding: 1rem;
  background-color: var(--bg-primary);
  border-radius: 0.5rem;
  border: 1px solid var(--border-color);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartWrapper:hover,
.pieChartWrapper:hover {
  box-shadow: var(--shadow-md);
  border-color: var(--accent-primary);
  transform: translateY(-2px);
}

@media (max-width: 1024px) {
  .chartRow {
    grid-template-columns: 1fr;
  }
  
  .pieChartWrapper {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .chartWrapper,
  .pieChartWrapper {
    height: 300px;
  }
}

@media (prefers-color-scheme: dark) {
  .chartWrapper,
  .pieChartWrapper {
    background: color-mix(in srgb, var(--bg-primary) 95%, var(--accent-primary));
  }
  
  .chartWrapper:hover,
  .pieChartWrapper:hover {
    background: color-mix(in srgb, var(--bg-primary) 90%, var(--accent-primary));
  }
}

/* Mobile adjustments */
@media (max-width: 640px) {
  .controls {
    gap: 0.375rem;
  }

  .controls .button {
    min-width: 70px;
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
  }

  .headerActions .button {
    min-width: 90px;
  }
}

/* Filter Controls */
.filterControls {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
  background: var(--surface-color);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}

.filterGroup {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.filterLabel {
  font-weight: 500;
  color: var(--text-secondary);
}

.filterSelect {
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  background: var(--background-color);
  color: var(--text-primary);
  font-size: var(--text-sm);
  cursor: pointer;
  transition: all 0.2s ease;
}

.filterSelect:hover {
  border-color: var(--primary-color);
}

.filterSelect:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--primary-light);
}

/* Chart Container */
.chartContainer {
  background: var(--surface-color);
  padding: 1.5rem;
  border-radius: var(--radius-lg);
  margin-bottom: 2rem;
  box-shadow: var(--shadow-sm);
}

/* Names Grid */
.namesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

/* Name Card Enhancements */
.nameCard {
  position: relative;
  transition: all 0.3s ease;
}

.nameCard.isHidden {
  background: var(--surface-color);
  opacity: 0.8;
  border: 1px dashed var(--border-color);
}

.nameCard.isHidden::before {
  content: '🔒';
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 1.2rem;
}

.nameCard:not(.isHidden) {
  background: linear-gradient(
    to bottom right,
    var(--card-background),
    var(--surface-color)
  );
  border: 1px solid var(--primary-light);
}

.nameHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.nameTitle {
  font-size: var(--text-lg);
  font-weight: 600;
  color: var(--text-primary);
}

.rating {
  font-size: var(--text-xl);
  font-weight: 700;
  color: var(--primary-color);
  padding: 0.25rem 0.75rem;
  background: var(--primary-light);
  border-radius: var(--radius-full);
}

.stats {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.record {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: var(--text-sm);
  color: var(--text-secondary);
}

.wins {
  color: var(--success-500);
}

.losses {
  color: var(--error-500);
}

.separator {
  color: var(--text-tertiary);
}

/* Stats Container Enhancement */
.statsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  background: var(--surface-color);
  border-radius: var(--radius-lg);
  transition: transform 0.2s ease;
}

.statItem:hover {
  transform: translateY(-2px);
}

.statLabel {
  font-size: var(--text-sm);
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.statValue {
  font-size: var(--text-2xl);
  font-weight: 700;
  color: var(--primary-color);
}

.statEmoji {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .filterControls {
    flex-direction: column;
    gap: 0.75rem;
  }

  .statsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .namesGrid {
    grid-template-columns: 1fr;
  }
}

/* Aggregated Section Enhancements */
.aggregatedHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.aggregatedSection {
  background-color: var(--bg-primary);
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
}

.aggregatedCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  transition: all 0.3s ease;
  position: relative;
}

.aggregatedCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  border-color: var(--accent-primary);
}

.aggregatedCard.isHidden {
  background: var(--bg-secondary);
  border: 2px dashed var(--border-color);
  opacity: 0.85;
}

.aggregatedCard.isHidden::before {
  content: '🔒';
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  font-size: 1.2rem;
  opacity: 0.7;
}

.aggregatedCard.isHidden:hover {
  opacity: 1;
  border-color: var(--accent-primary);
}

.aggregatedStats {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  background-color: var(--bg-secondary);
  border-radius: 0.75rem;
  margin-top: 0.5rem;
  transition: background-color 0.2s ease;
}

.aggregatedCard:hover .aggregatedStats {
  background-color: color-mix(in srgb, var(--bg-secondary) 95%, var(--accent-primary));
}

.statRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--bg-primary);
  transition: all 0.2s ease;
}

.aggregatedCard:hover .statRow {
  transform: translateX(2px);
}

.statLabel {
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.statValue {
  font-weight: 600;
  color: var(--text-primary);
  transition: color 0.2s ease;
}

.aggregatedCard:hover .statValue {
  color: var(--accent-primary);
}

/* Chart Enhancements */
.chartsContainer {
  background-color: var(--bg-primary);
  border-radius: 1rem;
  padding: 1.5rem;
  margin: 2rem 0;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
}

.chartRow {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.chartWrapper,
.pieChartWrapper {
  background-color: var(--bg-secondary);
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid var(--border-color);
  transition: all 0.3s ease;
  min-height: 400px;
}

.chartWrapper:hover,
.pieChartWrapper:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  border-color: var(--accent-primary);
}

/* Dark Mode Refinements */
@media (prefers-color-scheme: dark) {
  .aggregatedCard {
    background: color-mix(in srgb, var(--bg-primary) 95%, var(--accent-primary));
  }

  .aggregatedCard.isHidden {
    background: color-mix(in srgb, var(--bg-secondary) 95%, transparent);
    border-color: var(--border-color);
  }

  .aggregatedCard:hover {
    background: color-mix(in srgb, var(--bg-primary) 90%, var(--accent-primary));
  }

  .aggregatedStats {
    background-color: color-mix(in srgb, var(--bg-secondary) 90%, transparent);
  }

  .statRow {
    background-color: color-mix(in srgb, var(--bg-primary) 95%, transparent);
  }

  .chartWrapper,
  .pieChartWrapper {
    background-color: color-mix(in srgb, var(--bg-secondary) 95%, transparent);
  }
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .chartRow {
    grid-template-columns: 1fr;
  }

  .chartWrapper,
  .pieChartWrapper {
    min-height: 350px;
  }
}

@media (max-width: 768px) {
  .aggregatedHeader {
    flex-direction: column;
    align-items: stretch;
  }

  .chartWrapper,
  .pieChartWrapper {
    min-height: 300px;
    padding: 1rem;
  }

  .aggregatedCard {
    padding: 1.25rem;
  }

  .aggregatedStats {
    padding: 0.75rem;
  }
}

@media (max-width: 480px) {
  .aggregatedSection {
    padding: 1rem;
  }

  .chartWrapper,
  .pieChartWrapper {
    min-height: 250px;
  }

  .statRow {
    padding: 0.375rem;
    font-size: 0.8125rem;
  }
}

/* Chart Controls */
.chartHeader {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: var(--bg-secondary);
  border-radius: 0.5rem;
}

.chartControls {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  background: var(--bg-primary);
  border-radius: 0.375rem;
  border: 1px solid var(--border-color);
}

.chartLabel {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-secondary);
}

.chartSelect {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  color: var(--text-primary);
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.chartSelect:hover {
  border-color: var(--accent-primary);
}

.chartSelect:focus {
  outline: none;
  border-color: var(--accent-primary);
  box-shadow: 0 0 0 2px var(--primary-light);
}

/* Dark Mode Refinements */
@media (prefers-color-scheme: dark) {
  .chartHeader {
    background: color-mix(in srgb, var(--bg-secondary) 95%, transparent);
  }

  .chartControls {
    background: color-mix(in srgb, var(--bg-primary) 95%, var(--accent-primary));
  }

  .chartSelect {
    background: var(--bg-primary);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .chartHeader {
    margin-bottom: 0.75rem;
  }

  .chartControls {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }
} 