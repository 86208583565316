:root {
  /* Cool Color Palette for Black Kitten Theme */
  --primary-50: #f3f4ff;
  --primary-100: #e6e7ff;
  --primary-200: #cdceff;
  --primary-300: #a5a7ff;
  --primary-400: #7d7fff;
  --primary-500: #5a5cff;
  --primary-600: #4548ff;
  --primary-700: #3235db;
  --primary-800: #2426b3;
  --primary-900: #1c1d8a;
  
  /* Modern Grays */
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;
  
  /* Accent Colors */
  --purple-500: #8b5cf6;
  --purple-600: #7c3aed;
  
  /* Theme Colors - Softer Light Mode */
  --primary-color: var(--primary-600);
  --primary-light: var(--primary-100);
  --primary-dark: var(--primary-800);
  --surface-color: #f4f6fa;
  --background-color: #eef1f6;
  --text-primary: #2c3544;
  --text-secondary: #4b5563;
  --text-tertiary: #6b7280;
  --border-color: #e5e7eb;
  --card-background: #f8fafc;
  --card-border: #e5e7eb;
  --card-hover: #f1f5f9;
  
  /* Component Colors - Adjusted for Softer Light Mode */
  --progress-background: var(--primary-100);
  --progress-border: var(--primary-200);
  --percentage-background: var(--surface-color);
  --percentage-text: var(--primary-600);
  --vs-background: var(--primary-200);
  --vs-text: var(--primary-600);
  --header-background: rgba(244, 246, 250, 0.9);
  --header-border: var(--border-color);
  
  /* RGB Values for Opacity */
  --primary-rgb: 79, 70, 229;
  --error-rgb: 239, 68, 68;
  --success-rgb: 16, 185, 129;
  --warning-rgb: 245, 158, 11;
  --purple-rgb: 107, 70, 193;
  --black-rgb: 0, 0, 0;
  --white-rgb: 255, 255, 255;
  
  /* Effects */
  --shadow-color: rgba(var(--black-rgb), 0.1);
  --shadow-color-light: rgba(var(--black-rgb), 0.05);
  --shadow-color-medium: rgba(var(--black-rgb), 0.1);
  --shadow-color-strong: rgba(var(--black-rgb), 0.15);
  
  --shadow-sm: 0 1px 2px var(--shadow-color);
  --shadow-md: 0 4px 6px -1px var(--shadow-color), 0 2px 4px -2px var(--shadow-color);
  --shadow-lg: 0 10px 15px -3px var(--shadow-color), 0 4px 6px -4px var(--shadow-color);
  --shadow-xl: 0 20px 25px -5px var(--shadow-color), 0 8px 10px -6px var(--shadow-color);
  
  --text-shadow-sm: 0 2px 8px rgba(var(--black-rgb), 0.2);
  --text-shadow-lg: 0 2px 12px rgba(var(--black-rgb), 0.4);
  
  --overlay-light: rgba(var(--black-rgb), 0.4);
  --overlay-medium: rgba(var(--black-rgb), 0.5);
  --overlay-dark: rgba(var(--black-rgb), 0.75);
  --overlay-darker: rgba(var(--black-rgb), 0.9);
  
  --hover-overlay: rgba(var(--white-rgb), 0.1);
  --active-overlay: rgba(var(--white-rgb), 0.2);
  
  /* Typography */
  --font-sans: "Inter", system-ui, -apple-system, sans-serif;
  --font-serif: "Playfair Display", georgia, serif;
  --font-mono: "JetBrains Mono", monospace;
  
  --text-xs: 0.75rem;
  --text-sm: 0.875rem;
  --text-base: 1rem;
  --text-lg: 1.125rem;
  --text-xl: 1.25rem;
  --text-2xl: 1.5rem;
  --text-3xl: 1.875rem;
  --text-4xl: 2.25rem;
  --text-5xl: 3rem;
  
  --text-responsive-xs: clamp(0.75rem, 1.5vw, 0.875rem);
  --text-responsive-sm: clamp(0.875rem, 1.75vw, 1rem);
  --text-responsive-base: clamp(1rem, 2vw, 1.125rem);
  --text-responsive-lg: clamp(1.125rem, 2.25vw, 1.25rem);
  --text-responsive-xl: clamp(1.25rem, 2.5vw, 1.5rem);
  --text-responsive-2xl: clamp(1.5rem, 3vw, 2rem);
  
  /* Spacing */
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-5: 1.25rem;
  --space-6: 1.5rem;
  --space-8: 2rem;
  --space-10: 2.5rem;
  --space-12: 3rem;
  --space-16: 4rem;
  --space-20: 5rem;
  --space-24: 6rem;
  
  /* Border Radius */
  --radius-sm: 0.375rem;
  --radius-md: 0.5rem;
  --radius-lg: 0.75rem;
  --radius-xl: 1rem;
  --radius-2xl: 1.5rem;
  --radius-full: 9999px;
  
  /* Transitions */
  --transition-all: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-transform: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-opacity: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-smooth: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-bounce: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  
  /* Easing */
  --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
  --ease-in-out-expo: cubic-bezier(0.87, 0, 0.13, 1);
  --ease-elastic: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  
  /* Animation Duration */
  --duration-fast: 150ms;
  --duration-normal: 250ms;
  --duration-slow: 350ms;
  
  /* Z-Index */
  --z-negative: -1;
  --z-0: 0;
  --z-10: 10;
  --z-20: 20;
  --z-30: 30;
  --z-40: 40;
  --z-50: 50;
  --z-elevate: 1;
  --z-sticky: 100;
  --z-drawer: 200;
  --z-modal: 300;
  --z-popover: 400;
  --z-toast: 500;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: var(--primary-400);
    --primary-light: rgba(125, 127, 255, 0.15);
    --primary-dark: var(--primary-200);
    
    --surface-color: #0a0c1c;
    --background-color: #050714;
    --card-background: #0f1225;
    --card-border: #1a1f35;
    --card-hover: #151830;
    
    --text-primary: #f8fafc;
    --text-secondary: #e2e8f0;
    --text-tertiary: #94a3b8;
    
    --border-color: #1a1f35;
    
    --shadow-color: rgba(0, 0, 0, 0.5);
    --shadow-color-light: rgba(0, 0, 0, 0.2);
    --shadow-color-medium: rgba(0, 0, 0, 0.3);
    --shadow-color-strong: rgba(0, 0, 0, 0.4);
    
    --overlay-light: rgba(0, 0, 0, 0.5);
    --overlay-medium: rgba(0, 0, 0, 0.65);
    --overlay-dark: rgba(0, 0, 0, 0.85);
    --overlay-darker: rgba(0, 0, 0, 0.95);
  }
}

/* High Contrast Mode */
@media (prefers-contrast: more) {
  :root {
    --primary-600: #3235db;
    --error-600: #dc2626;
    --success-600: #059669;
    --warning-600: #b45309;
    --purple-600: #6d28d9;
  }
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}