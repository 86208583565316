.loginWrapper {
  min-height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  position: relative;
  padding: 2rem;
  overflow: hidden;
}

.backgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(8px) brightness(0.7);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  backdrop-filter: blur(4px);
}

.loginContainer {
  width: min(90%, 1000px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(16px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.imageSection {
  padding: 3rem;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;
}

.welcomeTitle {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0;
}

.welcomeText {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 90%;
  margin: 0;
}

.catImage {
  width: min(100%, 300px);
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.loginContent {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: rgba(255, 255, 255, 0.95);
}

.loginTitle {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin: 0 0 1rem;
}

.catFact {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
  font-style: italic;
  margin: 0;
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputWrapper {
  position: relative;
}

.loginInput {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  background: white;
  transition: all 0.2s ease;
}

.loginInput:focus {
  outline: none;
  border-color: #333;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.loginInput.error {
  border-color: #ef4444;
}

.errorMessage {
  color: #ef4444;
  font-size: 0.875rem;
  margin: 0.5rem 0 0;
}

.generateButton {
  composes: btnSecondary from '../../styles/base.css';
  width: 100%;
  padding: 0.875rem;
  font-size: 1rem;
  background: #1a1a1a;
  border: 2px solid #333;
  color: white;
  font-weight: 500;
  transition: all 0.2s var(--ease-out-expo);
}

.generateButton:hover:not(:disabled) {
  background: #2a2a2a;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-color: #444;
}

.generateButton:active:not(:disabled) {
  transform: translateY(0);
  background: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.generateButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.startButton {
  composes: btnPrimary from '../../styles/base.css';
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  position: relative;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.buttonEmoji {
  font-size: 1.2em;
}

.spinner {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin-right: 0.5rem;
}

.helperText {
  color: #666;
  font-size: 0.875rem;
  text-align: center;
  margin: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .loginContainer {
    grid-template-columns: 1fr;
    width: min(95%, 450px);
  }

  .imageSection {
    padding: 2rem;
  }

  .welcomeTitle {
    font-size: 2rem;
  }

  .loginContent {
    padding: 2rem;
  }

  .loginTitle {
    font-size: 1.75rem;
  }

  .catImage {
    width: min(80%, 250px);
  }
}

@media (prefers-color-scheme: dark) {
  .loginContent {
    background: rgba(17, 24, 39, 0.95);
  }

  .loginTitle {
    color: white;
  }

  .catFact {
    color: #9ca3af;
  }

  .loginInput {
    background: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.1);
    color: white;
  }

  .loginInput::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  .helperText {
    color: #9ca3af;
  }

  .generateButton {
    background: #222;
    border-color: #444;
    color: white;
  }

  .generateButton:hover:not(:disabled) {
    background: #333;
    border-color: #666;
  }
} 