.sidebar {
  composes: container from '../../styles/base.css';
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  height: 100vh;
  background: var(--bg-primary);
  border-right: 1px solid var(--border-color);
  transform: translateX(-100%);
  transition: transform 0.3s var(--ease-out);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: var(--shadow-lg);
  -webkit-overflow-scrolling: touch;
  will-change: transform;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.animating {
  pointer-events: none;
}

/* Add backdrop for mobile */
.backdrop {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s var(--ease-out);
  will-change: opacity;
  display: none;
}

.backdrop.open {
  opacity: 1;
}

.backdrop.animating {
  pointer-events: none;
}

@media (max-width: 768px) {
  .sidebarToggle {
    top: 1rem;
    left: 1rem;
    width: 36px;
    height: 36px;
    padding: 0.5rem;
  }

  .sidebarToggle span:not(.srOnly) {
    width: 16px;
  }

  .backdrop {
    display: block;
  }
  
  .sidebar {
    background: rgb(var(--black-rgb) / 0.9);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    box-shadow: var(--shadow-2xl);
  }

  /* Improve animation performance on mobile */
  .sidebar.animating,
  .backdrop.animating {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px;
    transform-style: preserve-3d;
  }
}

.sidebarToggle {
  composes: btnIcon from '../../styles/base.css';
  position: fixed;
  top: 1.25rem;
  left: 1.25rem;
  z-index: 1001;
  width: 40px;
  height: 40px;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-full);
  cursor: pointer;
  transition: all 0.2s var(--ease-out);
  box-shadow: var(--shadow-sm);
  transform-origin: center;
  will-change: transform;
}

.sidebarToggle:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-md);
  background: var(--bg-secondary);
}

.sidebarToggle:active {
  transform: scale(0.95);
}

.sidebarToggle span:not(.srOnly) {
  width: 18px;
  height: 2px;
  background: var(--text-primary);
  border-radius: 2px;
  transition: all 0.3s var(--ease-out);
  transform-origin: center;
  margin: 0 auto;
}

.sidebarToggle.open {
  background: var(--accent-primary);
  border-color: var(--accent-primary);
  box-shadow: var(--shadow-md);
}

.sidebarToggle.open span:not(.srOnly) {
  background: var(--bg-primary);
}

.sidebarToggle.open span:nth-child(2) {
  transform: translateY(6px) rotate(45deg);
}

.sidebarToggle.open span:nth-child(3) {
  opacity: 0;
  transform: scale(0);
}

.sidebarToggle.open span:nth-child(4) {
  transform: translateY(-6px) rotate(-45deg);
}

/* Screen reader only text */
.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sidebarHeader {
  padding: var(--space-6);
  border-bottom: 1px solid var(--border-color);
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: var(--space-4);
  margin-bottom: var(--space-4);
}

.sidebarCatImage {
  width: 40px;
  height: 40px;
  border-radius: var(--radius-full);
}

.appTitle {
  font-size: var(--text-xl);
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.userInfo {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.userGreeting {
  font-size: var(--text-sm);
  color: var(--text-secondary);
}

.userName {
  font-size: var(--text-base);
  font-weight: 500;
  color: var(--text-primary);
}

.sidebarNav {
  flex: 1;
  padding: var(--space-4);
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.navButton {
  composes: btnSecondary from '../../styles/base.css';
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--space-3);
  padding: var(--space-3) var(--space-4);
  text-align: left;
}

.active {
  composes: btnPrimary from '../../styles/base.css';
  background: var(--primary-color);
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--space-3);
  padding: var(--space-3) var(--space-4);
  text-align: left;
}

.buttonIcon {
  font-size: var(--text-xl);
}

.buttonText {
  flex: 1;
}

.sidebarFooter {
  padding: var(--space-4);
  border-top: 1px solid var(--border-color);
  margin-top: auto;
  background: rgb(var(--black-rgb) / 0.03);
}

.logoutButton {
  composes: btnDanger from '../../styles/base.css';
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--space-3);
  padding: var(--space-3) var(--space-4);
  font-size: var(--text-base);
  font-weight: 500;
  color: var(--error-600);
  background: rgb(var(--error-rgb) / 0.1);
  border: 1px solid rgb(var(--error-rgb) / 0.2);
  border-radius: var(--radius-lg);
  transition: all var(--duration-normal) var(--ease-out);
}

.logoutButton:hover {
  transform: translateY(-1px);
  background: rgb(var(--error-rgb) / 0.15);
  border-color: rgb(var(--error-rgb) / 0.3);
  box-shadow: 0 4px 12px rgb(var(--error-rgb) / 0.1);
}

.logoutButton:active {
  transform: translateY(0);
  background: rgb(var(--error-rgb) / 0.2);
}

.logoutButton .buttonIcon {
  font-size: var(--text-xl);
  color: var(--error-500);
  transition: transform var(--duration-normal) var(--ease-out);
}

.logoutButton:hover .buttonIcon {
  transform: translateX(-2px);
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .sidebar {
    background: rgb(var(--black-rgb) / 0.95);
    backdrop-filter: blur(12px);
  }

  .backdrop {
    background: rgb(var(--black-rgb) / 0.6);
  }

  .sidebarToggle {
    background: rgb(var(--black-rgb) / 0.8);
  }

  .sidebarFooter {
    background: rgb(var(--white-rgb) / 0.03);
    border-color: var(--border-color-dark);
  }

  .logoutButton {
    color: var(--error-400);
    background: rgb(var(--error-rgb) / 0.15);
    border-color: rgb(var(--error-rgb) / 0.2);
  }

  .logoutButton:hover {
    background: rgb(var(--error-rgb) / 0.2);
    border-color: rgb(var(--error-rgb) / 0.3);
    box-shadow: 0 4px 12px rgb(var(--black-rgb) / 0.3);
  }

  .logoutButton .buttonIcon {
    color: var(--error-400);
  }
}

/* Improved mobile styles */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    max-width: 320px;
    box-shadow: var(--shadow-2xl);
  }

  .sidebarHeader {
    padding: var(--space-4);
  }

  .sidebarNav {
    padding: var(--space-3);
  }

  .navButton,
  .active {
    padding: var(--space-4);
    min-height: 48px; /* Better touch targets */
  }

  .sidebarFooter {
    background: none;
    padding: var(--space-3);
    padding-bottom: max(var(--space-3), env(safe-area-inset-bottom));
  }

  .logoutButton {
    padding: var(--space-4);
    min-height: 48px;
  }
}

/* Focus styles */
.navButton:focus-visible,
.active:focus-visible,
.logoutButton:focus-visible,
.sidebarToggle:focus-visible {
  outline: 2px solid var(--primary-400);
  outline-offset: 2px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .sidebarToggle,
  .sidebarToggle span:not(.srOnly),
  .sidebar,
  .backdrop {
    transition: none;
  }
}

/* Safe area insets for modern mobile devices */
@supports (padding: max(0px)) {
  .sidebar {
    padding-top: max(var(--space-6), env(safe-area-inset-top));
    padding-bottom: max(var(--space-6), env(safe-area-inset-bottom));
  }
} 