.container {
  composes: cardInteractive from '../../styles/base.css';
  width: 100%;
  padding: var(--space-4);
  overflow-x: auto;
  background: var(--surface-color);
  border-radius: var(--radius-lg);
  /* Prevent horizontal bounce on macOS */
  overscroll-behavior-x: none;
  -webkit-overflow-scrolling: touch;
}

.bracket {
  position: relative;
  display: flex;
  gap: var(--space-8);
  align-items: flex-start;
  justify-content: flex-start;
  min-width: min-content;
  padding: var(--space-4);
  transform-origin: center;
  backface-visibility: hidden;
  /* Improve scrolling */
  scroll-snap-type: x proximity;
}

.round {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  scroll-snap-align: start;
}

/* First round specific styles */
.round:first-child {
  margin-right: var(--space-4);
}

.roundHeader {
  composes: cardInteractive from '../../styles/base.css';
  padding: var(--space-2);
  margin-bottom: var(--space-4);
  text-align: center;
  border-color: var(--primary-200);
  background: var(--surface-color);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
}

.roundTitle {
  display: block;
  margin-bottom: var(--space-1);
  font-size: var(--text-sm);
  font-weight: 600;
  color: var(--primary-500);
}

.roundMatches {
  font-size: var(--text-sm);
  color: var(--text-secondary);
}

.matches {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--space-4);
}

/* Progressive spacing between matches */
.round:nth-child(2) .matches { 
  gap: var(--space-8);
  padding-top: var(--space-4);
}

.round:nth-child(3) .matches { 
  gap: var(--space-16);
  padding-top: var(--space-8);
}

.match {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  animation: slideIn 0.3s var(--ease-out) forwards;
}

.matchConnector {
  position: absolute;
  right: calc(var(--space-8) * -1);
  top: 50%;
  width: var(--space-8);
  height: 2px;
  background: var(--primary-200);
  transform: translateY(-50%);
  opacity: 0.6;
  transition: all var(--duration-normal) var(--ease-out);
}

.match:hover .matchConnector {
  opacity: 1;
  background: var(--primary-400);
}

.matchContent {
  composes: cardInteractive from '../../styles/base.css';
  position: relative;
  z-index: 2;
  width: 100%;
  padding: var(--space-3);
  background: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  transition: all var(--duration-normal) var(--ease-out);
  box-shadow: var(--shadow-sm);
}

.matchContent:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
  border-color: var(--primary-300);
}

.player {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-2) var(--space-3);
  margin-bottom: var(--space-2);
  background: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  transition: all var(--duration-normal) var(--ease-out);
}

.player:last-child {
  margin-bottom: 0;
}

.playerWinner {
  composes: player;
  background: rgb(var(--primary-rgb) / 0.1);
  border-color: var(--primary-300);
  color: var(--primary-700);
  font-weight: 500;
}

.playerLoser {
  composes: player;
  opacity: 0.6;
  background: var(--surface-color);
  color: var(--text-secondary);
}

.playerBothWin {
  composes: player;
  background: rgb(var(--success-rgb) / 0.1);
  border-color: var(--success-300);
  color: var(--success-700);
  font-weight: 500;
}

.playerNeither {
  composes: player;
  background: rgb(var(--warning-rgb) / 0.05);
  border-color: var(--warning-200);
  color: var(--warning-700);
  opacity: 0.8;
}

.playerBye {
  composes: player;
  opacity: 0.5;
  font-style: italic;
  background: var(--surface-color);
  border-style: dashed;
}

.playerName {
  font-weight: 500;
  color: inherit;
  margin-right: var(--space-2);
  transition: color var(--duration-normal) var(--ease-out);
}

.winnerBadge {
  padding: var(--space-1) var(--space-2);
  font-size: var(--text-xs);
  font-weight: 500;
  color: var(--primary-500);
  background: var(--primary-50);
  border-radius: var(--radius-full);
  transition: all var(--duration-normal) var(--ease-out);
}

.tieBadge {
  composes: winnerBadge;
  color: var(--success-500);
  background: var(--success-50);
}

.skipBadge {
  composes: winnerBadge;
  color: var(--warning-500);
  background: var(--warning-50);
}

.vsDivider {
  text-align: center;
  font-size: var(--text-xs);
  color: var(--text-secondary);
  margin: var(--space-1) 0;
  opacity: 0.6;
}

.byeText {
  color: var(--text-secondary);
  font-size: var(--text-sm);
}

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-8);
  color: var(--text-secondary);
  font-size: var(--text-sm);
  background: var(--surface-color);
  border: 1px dashed var(--border-color);
  border-radius: var(--radius-lg);
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .bracket {
    gap: var(--space-6);
    padding: var(--space-2);
  }

  .round {
    min-width: 200px;
  }

  .matchContent {
    padding: var(--space-2);
  }

  .player {
    padding: var(--space-2);
  }

  .matchConnector {
    right: calc(var(--space-4) * -1);
    width: var(--space-4);
  }
}

/* Fix Safari flexbox issues */
@supports (-webkit-touch-callout: none) {
  .matches {
    height: auto;
  }
  
  .round:first-child {
    height: fit-content;
  }
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .container {
    background: var(--surface-dark);
  }

  .roundHeader {
    background: var(--surface-dark);
    border-color: var(--border-color-dark);
  }

  .matchContent {
    background: var(--surface-dark);
    border-color: var(--border-color-dark);
  }

  .player {
    background: var(--surface-dark);
    border-color: var(--border-color-dark);
  }

  .playerWinner {
    background: rgb(var(--primary-rgb) / 0.2);
    border-color: var(--primary-400);
  }

  .playerBothWin {
    background: rgb(var(--success-rgb) / 0.2);
    border-color: var(--success-400);
  }

  .playerNeither {
    background: rgb(var(--warning-rgb) / 0.1);
    border-color: var(--warning-300);
  }

  .playerBye {
    background: var(--surface-dark);
    border-color: var(--border-color-dark);
  }

  .matchConnector {
    background: var(--primary-300);
  }

  .match:hover .matchConnector {
    background: var(--primary-500);
  }

  .winnerBadge {
    background: rgb(var(--primary-rgb) / 0.2);
  }

  .tieBadge {
    background: rgb(var(--success-rgb) / 0.2);
  }

  .skipBadge {
    background: rgb(var(--warning-rgb) / 0.2);
  }

  .emptyState {
    background: var(--surface-dark);
    border-color: var(--border-color-dark);
    color: var(--text-secondary-dark);
  }
}

/* Accessibility improvements */
@media (prefers-reduced-motion: reduce) {
  .matchContent:hover {
    transform: none;
  }

  .match {
    animation: none;
  }
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
} 