.button {
  background: var(--primary-600);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid var(--primary-700);
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background: var(--primary-700);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.button:disabled {
  background: var(--neutral-500);
  border-color: var(--neutral-600);
  opacity: 0.7;
  cursor: not-allowed;
}

.icon {
  font-size: 1.25rem;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .button {
    background: var(--primary-700);
    border-color: var(--primary-800);
    color: var(--text-primary-dark);
  }

  .button:hover {
    background: var(--primary-800);
    border-color: var(--primary-900);
  }

  .button:disabled {
    background: var(--neutral-700);
    border-color: var(--neutral-800);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .button {
    width: 100%;
    min-width: unset;
    padding: var(--space-3) var(--space-4);
  }
}

/* Accessibility */
.button:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

@media (prefers-reduced-motion: reduce) {
  .button {
    transition: none;
  }
  
  .button:hover {
    transform: none;
  }
} 