/* Layout */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--space-4);
}

/* Flexbox */
.flex { display: flex; }
.flex-col { flex-direction: column; }
.flex-row { flex-direction: row; }
.flex-wrap { flex-wrap: wrap; }
.items-center { align-items: center; }
.items-start { align-items: flex-start; }
.items-end { align-items: flex-end; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-start { justify-content: flex-start; }
.justify-end { justify-content: flex-end; }
.flex-1 { flex: 1; }
.flex-auto { flex: auto; }
.flex-none { flex: none; }

/* Grid */
.grid { display: grid; }
.grid-cols-1 { grid-template-columns: repeat(1, minmax(0, 1fr)); }
.grid-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
.grid-cols-3 { grid-template-columns: repeat(3, minmax(0, 1fr)); }
.grid-cols-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }
.gap-2 { gap: var(--space-2); }
.gap-4 { gap: var(--space-4); }
.gap-6 { gap: var(--space-6); }
.gap-8 { gap: var(--space-8); }

/* Spacing */
.m-0 { margin: 0; }
.m-2 { margin: var(--space-2); }
.m-4 { margin: var(--space-4); }
.m-6 { margin: var(--space-6); }
.m-8 { margin: var(--space-8); }

.mt-2 { margin-top: var(--space-2); }
.mt-4 { margin-top: var(--space-4); }
.mt-6 { margin-top: var(--space-6); }
.mt-8 { margin-top: var(--space-8); }

.mb-2 { margin-bottom: var(--space-2); }
.mb-4 { margin-bottom: var(--space-4); }
.mb-6 { margin-bottom: var(--space-6); }
.mb-8 { margin-bottom: var(--space-8); }

.ml-2 { margin-left: var(--space-2); }
.ml-4 { margin-left: var(--space-4); }
.ml-6 { margin-left: var(--space-6); }
.ml-8 { margin-left: var(--space-8); }

.mr-2 { margin-right: var(--space-2); }
.mr-4 { margin-right: var(--space-4); }
.mr-6 { margin-right: var(--space-6); }
.mr-8 { margin-right: var(--space-8); }

.p-0 { padding: 0; }
.p-2 { padding: var(--space-2); }
.p-4 { padding: var(--space-4); }
.p-6 { padding: var(--space-6); }
.p-8 { padding: var(--space-8); }

.pt-2 { padding-top: var(--space-2); }
.pt-4 { padding-top: var(--space-4); }
.pt-6 { padding-top: var(--space-6); }
.pt-8 { padding-top: var(--space-8); }

.pb-2 { padding-bottom: var(--space-2); }
.pb-4 { padding-bottom: var(--space-4); }
.pb-6 { padding-bottom: var(--space-6); }
.pb-8 { padding-bottom: var(--space-8); }

.pl-2 { padding-left: var(--space-2); }
.pl-4 { padding-left: var(--space-4); }
.pl-6 { padding-left: var(--space-6); }
.pl-8 { padding-left: var(--space-8); }

.pr-2 { padding-right: var(--space-2); }
.pr-4 { padding-right: var(--space-4); }
.pr-6 { padding-right: var(--space-6); }
.pr-8 { padding-right: var(--space-8); }

/* Typography */
.text-xs { font-size: var(--text-xs); }
.text-sm { font-size: var(--text-sm); }
.text-base { font-size: var(--text-base); }
.text-lg { font-size: var(--text-lg); }
.text-xl { font-size: var(--text-xl); }
.text-2xl { font-size: var(--text-2xl); }
.text-3xl { font-size: var(--text-3xl); }
.text-4xl { font-size: var(--text-4xl); }
.text-5xl { font-size: var(--text-5xl); }

.font-sans { font-family: var(--font-sans); }
.font-serif { font-family: var(--font-serif); }
.font-mono { font-family: var(--font-mono); }

.font-normal { font-weight: 400; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.text-primary { color: var(--text-primary); }
.text-secondary { color: var(--text-secondary); }
.text-tertiary { color: var(--text-tertiary); }

.leading-none { line-height: 1; }
.leading-tight { line-height: 1.25; }
.leading-normal { line-height: 1.5; }
.leading-relaxed { line-height: 1.75; }

/* Colors */
.bg-primary { background-color: var(--primary-color); }
.bg-surface { background-color: var(--surface-color); }
.bg-background { background-color: var(--background-color); }
.bg-card { background-color: var(--card-background); }

.border-primary { border-color: var(--primary-color); }
.border-surface { border-color: var(--surface-color); }
.border-card { border-color: var(--card-border); }

/* Effects */
.shadow-sm { box-shadow: var(--shadow-sm); }
.shadow-md { box-shadow: var(--shadow-md); }
.shadow-lg { box-shadow: var(--shadow-lg); }
.shadow-xl { box-shadow: var(--shadow-xl); }

.rounded-sm { border-radius: var(--radius-sm); }
.rounded-md { border-radius: var(--radius-md); }
.rounded-lg { border-radius: var(--radius-lg); }
.rounded-xl { border-radius: var(--radius-xl); }
.rounded-2xl { border-radius: var(--radius-2xl); }
.rounded-full { border-radius: var(--radius-full); }

/* States */
.disabled {
  opacity: 0.7;
  pointer-events: none;
  cursor: not-allowed;
}

.hidden { display: none; }
.invisible { visibility: hidden; }
.visible { visibility: visible; }

.opacity-0 { opacity: 0; }
.opacity-25 { opacity: 0.25; }
.opacity-50 { opacity: 0.5; }
.opacity-75 { opacity: 0.75; }
.opacity-100 { opacity: 1; }

/* Z-index */
.z-0 { z-index: var(--z-0); }
.z-10 { z-index: var(--z-10); }
.z-20 { z-index: var(--z-20); }
.z-30 { z-index: var(--z-30); }
.z-40 { z-index: var(--z-40); }
.z-50 { z-index: var(--z-50); }
.z-auto { z-index: auto; }

/* Position */
.relative { position: relative; }
.absolute { position: absolute; }
.fixed { position: fixed; }
.sticky { position: sticky; }

.top-0 { top: 0; }
.right-0 { right: 0; }
.bottom-0 { bottom: 0; }
.left-0 { left: 0; }
.inset-0 { inset: 0; }

/* Responsive */
@media (max-width: 640px) {
  .sm\:hidden { display: none; }
  .sm\:block { display: block; }
  .sm\:flex { display: flex; }
  .sm\:grid { display: grid; }
  .sm\:grid-cols-1 { grid-template-columns: repeat(1, minmax(0, 1fr)); }
  .sm\:flex-col { flex-direction: column; }
  .sm\:text-sm { font-size: var(--text-sm); }
  .sm\:text-base { font-size: var(--text-base); }
}

@media (max-width: 768px) {
  .md\:hidden { display: none; }
  .md\:block { display: block; }
  .md\:flex { display: flex; }
  .md\:grid { display: grid; }
  .md\:grid-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .md\:flex-row { flex-direction: row; }
  .md\:text-lg { font-size: var(--text-lg); }
  .md\:text-xl { font-size: var(--text-xl); }
}

@media (max-width: 1024px) {
  .lg\:hidden { display: none; }
  .lg\:block { display: block; }
  .lg\:flex { display: flex; }
  .lg\:grid { display: grid; }
  .lg\:grid-cols-3 { grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .lg\:text-2xl { font-size: var(--text-2xl); }
  .lg\:text-3xl { font-size: var(--text-3xl); }
}

/* Animations */
.animate-fade-in { animation: fadeIn var(--duration-normal) ease-out; }
.animate-slide-up { animation: slideUp var(--duration-normal) var(--ease-out-expo); }
.animate-pulse { animation: pulse 2s infinite; }

/* Transitions */
.transition-all { transition: var(--transition-all); }
.transition-transform { transition: var(--transition-transform); }
.transition-opacity { transition: var(--transition-opacity); }
.transition-colors { transition: color var(--transition-smooth), background-color var(--transition-smooth), border-color var(--transition-smooth); }

/* Hover Effects */
.hover\:shadow-md:hover { box-shadow: var(--shadow-md); }
.hover\:shadow-lg:hover { box-shadow: var(--shadow-lg); }
.hover\:scale-105:hover { transform: scale(1.05); }
.hover\:translate-y-1:hover { transform: translateY(-0.25rem); }
.hover\:opacity-75:hover { opacity: 0.75; }
.hover\:opacity-100:hover { opacity: 1; }

/* Focus Effects */
.focus\:outline-none:focus { outline: none; }
.focus\:ring:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(var(--primary-rgb), 0.1);
}

/* Active Effects */
.active\:scale-95:active { transform: scale(0.95); }
.active\:translate-y-0:active { transform: translateY(0); }

/* Layout */
.grid {
  display: grid;
  gap: var(--space-6);
}

.flex {
  display: flex;
  gap: var(--space-4);
}

.flex-col {
  display: flex;
  flex-direction: column;
}

/* Alignment */
.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

/* Spacing */
.gap-2 {
  gap: var(--space-2);
}

.gap-4 {
  gap: var(--space-4);
}

.gap-6 {
  gap: var(--space-6);
}

.m-0 {
  margin: 0;
}

.mb-2 {
  margin-bottom: var(--space-2);
}

.mb-4 {
  margin-bottom: var(--space-4);
}

.mb-6 {
  margin-bottom: var(--space-6);
}

.mb-8 {
  margin-bottom: var(--space-8);
}

/* Text Alignment */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Text Colors */
.text-primary {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.text-tertiary {
  color: var(--text-tertiary);
}

/* Typography */
.font-serif {
  font-family: var(--font-serif);
}

.font-mono {
  font-family: var(--font-mono);
}

.text-sm {
  font-size: var(--text-sm);
}

.text-base {
  font-size: var(--text-base);
}

.text-lg {
  font-size: var(--text-lg);
}

.text-xl {
  font-size: var(--text-xl);
}

.text-2xl {
  font-size: var(--text-2xl);
}

.text-3xl {
  font-size: var(--text-3xl);
}

.text-4xl {
  font-size: var(--text-4xl);
}

/* Dimensions */
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

/* Position */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/* Display */
.hidden {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

/* Animations */
.fade-in {
  animation: fadeIn 0.3s ease-in;
}

.slide-up {
  animation: slideUp 0.3s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Controls */
.button-primary:hover:not(:disabled) {
  background: var(--primary-700);
}

.button-primary:active:not(:disabled) {
  background: var(--primary-800);
}

.button-secondary:hover:not(:disabled) {
  background: var(--primary-200);
  border-color: var(--primary-600);
}

.control-primary:hover:not(:disabled) {
  background: var(--primary-dark);
  transform: translateY(-1px);
}

.control-secondary:hover:not(:disabled) {
  background: var(--card-hover);
  border-color: var(--border-color);
  transform: translateY(-1px);
}

/* Cards */
.card-base:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
  box-shadow: var(--shadow-lg);
}

/* Navigation */
.nav-menu {
  display: flex;
  gap: var(--space-2);
}

.nav-menu button {
  padding: var(--space-2) var(--space-4);
  font-size: var(--text-base);
  color: var(--text-secondary);
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: var(--radius-lg);
  transition: var(--transition-all);
}

.nav-menu button:hover {
  color: var(--primary-700);
  background: var(--primary-100);
}

.nav-menu button.active {
  color: white;
  background: var(--primary-500);
}

/* User Controls */
.user-controls {
  position: relative;
  z-index: var(--z-10);
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-6);
  align-items: center;
  justify-content: center;
}

.user-welcome {
  display: flex;
  gap: var(--space-2);
  align-items: center;
  font-size: var(--text-lg);
  color: var(--text-secondary);
}

.user-welcome::before {
  content: "👋";
}

.logout-button {
  padding: var(--space-2) var(--space-4);
  font-size: var(--text-sm);
  color: var(--text-secondary);
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  transition: var(--transition-all);
}

.logout-button:hover {
  color: var(--primary-600);
  background: var(--primary-50);
  border-color: var(--primary-300);
}

/* Responsive */
@media (width <= 768px) {
  .user-controls {
    flex-direction: column;
    gap: var(--space-4);
  }
  
  .nav-menu {
    justify-content: center;
    width: 100%;
  }
  
  .user-welcome {
    font-size: var(--text-base);
  }
  
  .card-base {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .card-base:active {
    transform: scale(0.98);
  }
  
  .name-card:hover {
    box-shadow: var(--shadow-md);
  }
}

@media (width <= 480px) {
  .nav-menu button {
    padding: var(--space-2) var(--space-3);
  }
}