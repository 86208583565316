/* Common Shared Styles */
.pageContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--space-4);
}

.cardInteractive {
  background: var(--surface-color);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-lg);
  transition: all var(--duration-normal) var(--ease-out);
}

.gridLayout {
  display: grid;
  gap: var(--space-4);
}

/* Export values for composition */
:export {
  cardBase: cardInteractive;
  pageContainer: pageContainer;
  gridLayout: gridLayout;
} 