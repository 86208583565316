.container {
  composes: container from '../../styles/base.css';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  padding: var(--space-8);
  text-align: center;
  gap: var(--space-6);
}

.content {
  composes: cardInteractive from '../../styles/base.css';
  max-width: 600px;
  padding: var(--space-8);
  border-radius: var(--radius-xl);
  animation: slideIn 0.5s var(--ease-out);
}

.icon {
  font-size: var(--text-4xl);
  margin-bottom: var(--space-4);
}

.title {
  composes: heading2 from '../../styles/base.css';
  margin-bottom: var(--space-4);
  color: var(--error-600);
}

.message {
  composes: text from '../../styles/base.css';
  color: var(--text-secondary);
  margin-bottom: var(--space-6);
  line-height: 1.6;
}

.refreshButton {
  composes: btnPrimary from '../../styles/base.css';
  display: inline-flex;
  align-items: center;
  gap: var(--space-2);
  padding: var(--space-3) var(--space-6);
  font-size: var(--text-lg);
  transition: all var(--duration-normal) var(--ease-out);
}

.refreshButton:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.refreshIcon {
  animation: spin 2s linear infinite;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .content {
    background: var(--surface-dark);
    border-color: var(--border-color-dark);
  }

  .title {
    color: var(--error-400);
  }

  .message {
    color: var(--text-secondary-dark);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: var(--space-4);
  }

  .content {
    padding: var(--space-6);
  }

  .title {
    font-size: var(--text-xl);
  }

  .message {
    font-size: var(--text-base);
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .content {
    animation: none;
  }

  .refreshButton:hover {
    transform: none;
  }

  .refreshIcon {
    animation: none;
  }
} 